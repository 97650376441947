export const heroContent = {
    heading: "Wunderbar, dass du hier bist!",
    subheading: "Ich bin Jolina Wellenmarie",
    description: "🤍 Deine Birthkeeperin/Doula in Berlin",
    descriptionNote: "(online & offline)",
    introText: "Meine Website befindet sich aktuell noch im Aufbau. Möchtest du, dass ich Dich auf Deinem individuellen Wellenweg begleite? Dann schreib mir gern eine E-Mail. Ich freue mich auf dich!",
    quote: "Jede Welle (Wehe) bringt Dich näher zu Deinem Kind.",
    closingText: "Deine Jolina Wellenmarie",
    email: "jolina.wellenmarie@outlook.de",
    instagram: "https://www.instagram.com/jolina_wellenmarie/",
    imgSrc:"/Jolina_Hero_1.jpeg",
    backgroundImg: "/bg_brown.png"
};
