import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//import Navbar from './components/Nav'; 
import Hero from './components/Hero';
//import AboutMe from './components/2_AboutMe';
//import Offer from './components/Offer';
//import Approach from './components/Approach';
//import FAQ from './components/FAQ';
//import Footer from './components/Footer';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <>
            <div id="hero">
              <Hero />
            </div>
          </>
        } />
      </Routes>
    </Router>
  );
};

export default App;
