import React from 'react';
import { FaEnvelope } from 'react-icons/fa';
import { heroContent } from '../data/heroData';

const Hero = () => {
    return (
        <div
            className="relative bg-cover bg-center"
            style={{
                backgroundImage: `url(${heroContent.backgroundImg})`
            }}
        >
            <div className="flex flex-col lg:flex-row items-center justify-center min-h-screen">
                <div className="max-w-5xl px-4 xl:px-0  mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
                        <div className="text-center md:text-left lg:text-left sm:mx-4 md:mx-0">
                            <h2 className="text-lg sm:text-xl md:text-2xl lg:text-2xl font-bold leading-tight text-white font-heading">
                                {heroContent.heading} <br />
                                <span className='text-sky-900'>{heroContent.subheading}</span>
                            </h2>
                            <div>
                                <p className="max-w-lg text-base sm:text-lg md:text-xl leading-relaxed text-sundown-500 mt-2 sm:mt-4 font-bold">
                                    {heroContent.description} <br /> <span className='text-sm'>{heroContent.descriptionNote}</span>
                                </p>
                                <div className="max-w-lg text-base sm:text-lg md:text-xl lg:text-lg leading-relaxed text-gray-100 mt-2">
                                    {heroContent.introText}
                                    <blockquote className="text-sm italic font-semibold text-gray-100 mt-1">
                                        <span>"{heroContent.quote}"</span>
                                    </blockquote>
                                </div>
                                <p className="max-w-lg text-base sm:text-lg md:text-xl leading-relaxed text-white mt-2 my-2">
                                    {heroContent.closingText}
                                </p>
                                <a href={`mailto:${heroContent.email}`} className="flex items-center justify-center md:justify-start text-sundown-500 hover:text-sundown-600 text-sm group transition-colors">
                                    <FaEnvelope className="mr-2 transition-transform transform group-hover:rotate-4 group-hover:scale-110" />
                                    {heroContent.email}
                                </a>
                            </div>
                        </div>

                        <div className="relative p-2 bg-gray-100 rounded-lg shadow-md overflow-hidden w-60 justify-self-center">
                            <div className="absolute inset-0 opacity-50 transform skew-y-6"></div>
                            <div className="absolute -top-4 -right-4 w-16 h-16 bg-white rounded-full opacity-30"></div>
                            <img src={heroContent.imgSrc} alt="Jolina" className="relative w-full h-auto rounded-lg z-10" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
